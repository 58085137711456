import React from "react";
import Layout from "~/components/layout";
import Card from "~/components/styled/card";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const OurCompany = () => {
	return (
		<Layout>
			<GatsbySeo
				title="About us"
				description="About company, story and mission of Roots & Routes."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/about-us/our-company",
				// 	},
				// 	{
				// 		hrefLang: "en-US",
				// 		href: "https://www.artofbicycletrips.com/about-us/our-company",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/about-us/our-company",
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: "https://www.artofbicycletrips.com/about-us/our-company",
				// 	},
				// ]}
			/>
			<div className="my-20">
				<h1 className="font-bold">About Us</h1>
				<h2 className="font-light text-primary max-w-3xl">
					We're on a mission to inspire, empower and connect family adventurers
				</h2>
				{/* <p className="prose prose-sm sm:prose lg:prose-lg my-10">
					Founded in 2010 during a 100km bumpy ride in India, Roots & Routes
					specialize in crafting unique experiences to India and Asia-at-Large. Each
					day is crafted with utmost care so that you are well-rested; get a drift of
					local culture and cuisine and enjoy the splendid backroads at your own
					pace.
				</p>
				<p className="prose prose-sm sm:prose lg:prose-lg my-10">
					With our core focus on activities, we create and deliver exceptional
					experiences across Asia. We work with some of the finest local specialists
					based in your tour region as co-creators from itinerary designing to
					on-ground operations so that you experience the destination from a local
					perspective. We’re all about small and local details.
				</p> */}
				{/* <p className="prose prose-sm sm:prose lg:prose-lg my-10">
					At Roots n Routes, we believe family outings should be more than just
					activities—they should be transformative experiences. Designed with
					families in mind, our journeys go beyond hiking, inspiring curiosity and
					fostering confidence in children while creating lasting memories for
					everyone.
				</p> */}

				{/* Our Story */}
				<section className="my-20">
					<div className="flex flex-col lg:flex-row items-center lg:justify-between lg:pb-8">
						<div className="lg:w-1/2 lg:pr-8">
							<StaticImage
								className="mx-auto lg:mx-0 lg:w-full rounded-lg shadow-lg"
								src="../../images/family-pankaj.jpg"
								alt="pankaj mangal"
								loading="lazy"
							/>
						</div>
						<div className="lg:w-1/2 lg:pl-6">
							<h2 className="text-2xl font-semibold mb-4 mt-4 lg:mt-0 uppercase">
								Our Story
							</h2>
							<p className="text-gray-700">
								Roots n Routes was born during a family hike that changed everything.
								Watching our 3.5-year-old son thrive—befriending strangers, embracing
								animals, savoring local food—that experience was transformative, not
								just for him but for us as a family. We saw the beauty of stepping away
								from the fast-paced digital world to rediscover the simple joys of human
								connection, nature, and culture.
							</p>
							<br />
							<p className="text-gray-700">
								Inspired by this realization, we founded Roots n Routes. Our goal is to
								create immersive, nature-based hiking adventures that help families bond
								while discovering the richness of different cultures, landscapes, and
								ways of life. Each journey is an opportunity for children and parents
								alike to reconnect—with themselves, with each other, and with the world.
							</p>
							{/* <p className="text-gray-700">
								At Roots n Routes, every step is an exploration of the paths that
								connect us, weaving stories of togetherness and discovery. Welcome to
								the journey!
							</p> */}
						</div>
					</div>
					<div className="flex justify-center items-center ">
						<div className="w-full md:w-1/2 lg:w-1/2 p-4">
							<div className="bg-white text-center">
								<p className=" text-xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-medium">
									"The profound transformation we underwent during our inaugural family
									hiking journey compelled us to bestow similar enriching experience upon
									fellow families."
								</p>
							</div>
						</div>
					</div>
				</section>

				{/* <h3 className="mt-20 text-primary mb-4">Our story</h3>
				<p className="prose prose-sm sm:prose lg:prose-lg">
					Our journey started when three of us left one morning for a 100km ride to
					Cauvery Fishing Camp (off Bangalore), we came back surprised, out of our
					comfort zones, enthralled by the outdoors and moved by the simple living in
					the countryside. We had ridden out of the city for about 60km and were
					sitting under a statue of Mahatma Gandhi discussing the ride till then. We
					realized for some bicycle is a utility, for others it is a revelation of
					human potential and endurance, and for many more it is a new and unique
					experience – a perfect way to discover the heart of the country.
				</p>
				<h3 className="mt-20 text-primary mb-4">
					Our guiding principles and business ethics
				</h3>
				<div className="prose prose-sm sm:prose lg:prose-lg">
					<ul>
						<li>
							We believe cycling and hiking are a catalyst for a new paradigm based on
							the human traits of integrity, empathy, and transparency, and encourage
							people to follow along the same lines.
						</li>
						<li>
							We are committed to being honest, transparent, and accurate when we
							communicate with customers, whether in advertising, product information,
							or more detailed travel outline.
						</li>
						<li>
							We are committed to proactively dealing with issues and complaints if
							things go wrong and will make every effort to make it right.
						</li>
						<li>
							We believe in small-scale units, communal ownership, and regional
							workplaces utilising local labour and resources.
						</li>
						<li>
							We put sustainability at the core of business decisions and strive to
							achieve sustainable profitability. Sustainable profitability for a
							business means that an organisation provides a service or product that is
							both profitable and environmentally friendly.
						</li>
						<li>
							We care about our planet and ask questions about the cost in human terms,
							in health and environment, through our actions.
						</li>
					</ul>
				</div> */}
				{/* <h3 className="mt-20 text-primary mb-4">Our leadership</h3>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-20">
					<Card>
						<div className="">
							<StaticImage
								className="rounded-t-lg shadow-lg transform"
								src="../../images/pankaj.JPG"
								alt="pankaj mangal"
								layout="FULL_WIDTH"
								aspectRatio="1.8"
							/>
						</div>
						<h4 className="px-4 py-4">Pankaj Mangal</h4>
						<p className="px-4 pb-4 prose prose-sm">
							Having undergone the rigmarole of meeting rooms and pie charts, Pankaj
							savors nothing but hot cups of tea and some local gossip with villagers
							in faraway places. The last many years have been largely on his set of
							wheels, since he set out to explore his own bizarre country - the long
							bumpy rides, traversing miles of isolated roads and sharing stories with
							strangers has been absolutely worth it!
						</p>
					</Card>
					<Card>
						<div className="">
							<StaticImage
								className="rounded-t-lg shadow-lg transform"
								src="../../images/dibin.jpeg"
								alt="dibin"
								layout="FULL_WIDTH"
								aspectRatio="1.8"
							/>
						</div>
						<h4 className="px-4 py-4">Dibin Devassy</h4>
						<p className="px-4 pb-4 prose prose-sm">
							After six years, Dibin was happy to leave Dubai’s clinically clean
							environs and come right back to exploring the wonderfully unkempt and
							thickly green landscape of good old Kerala on his cycle. Amongst the
							diverse Kerala experiences, Dibin loves the traditional cuisines and
							organic farming methods the most! If he’s not scouring through the Kerala
							trails, you will find him hooked to movies with an intellectual tug; Into
							the Wild is his favourite! Oh not to forget he has cycled from Bangalore
							to Ladakh in one month.
						</p>
					</Card>
				</div> */}
			</div>
		</Layout>
	);
};

export default OurCompany;
